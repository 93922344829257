import React from 'react'
import {withPrefix,navigate } from "gatsby"
import Header from "./header_animated"
import Footer from "./footer_animated"
import PageScroll from './gaPageScroll'
import Transition from "./transitions"

import withLocation from "./withLocation"
import {isIE} from 'react-device-detect';





  // <Scroll callbacks={location} />


const TemplateWrapper = ({ children, location, pageContext,...props }) => {

      if (isIE && location.pathname !== withPrefix("/error")) {
         navigate("/error")
         return null
       }

  return(
isIE ? <div>{children}</div>:
  <>
    <Header location={location}/>
    <PageScroll/>


    <div className={location.pathname === withPrefix("/") ? "home" : "projectsolutions"} >
          <Transition location={location}>{children}</Transition>

    </div>
        <Footer location={location}/>
</>

)
}

export default withLocation(TemplateWrapper)
