import React from "react"
import {
  TransitionGroup,
  Transition as ReactTransition,
} from "react-transition-group"

const timeout = 250
const timeout2 = 400
const getTransitionChildren = {
  entering: {
    position: `fixed`,
    opacity: 0,
    transition: `opacity ${timeout2}ms ease-in-out`,
  },
  entered: {
    transition: `opacity ${timeout2}ms ease-in-out`,
    opacity: 1,
  },
  exiting: {
    transition: `opacity ${timeout2}ms ease-in-out`,
    opacity: 0,
  },
}

const getTransitionStyles = {
  entering: {
    zIndex:30000,
    position:'fixed',
    top:0,
    left:"-100vw",
    width:'100vw',
    height:'100vh',
    backgroundColor:"#ffffff",
    transition: `all ${timeout}ms ease-in-out 0.4s`,
  },
  entered: {

      zIndex:30000,
    top:0,
    left:0,
      position:'fixed',
    width:'100vw',
    height:'100vh',
    backgroundColor:"#ffffff",
    transition: `all ${timeout}ms ease-in-out `,
    transform: 'translate3d(110vw, 0, 0)'
  },
  exiting: {
      zIndex:30000,
    top:0,
    left:0,
    position:'fixed',
    width:'10vw',
    height:'100vh',
    backgroundColor:"#ffffff",
    transition: `all ${timeout}ms ease-in-out`,

  },
  }

class Transition extends React.PureComponent {
  render() {
    const { children, location } = this.props

    return (
      <TransitionGroup>
        <ReactTransition
          key={location.pathname}
          timeout={{
            enter: timeout,
            exit: timeout,
          }}
        >
          {status => (
            <div>
            <div
              style={{
                ...getTransitionStyles[status],
              }}
            >

            </div>
            <div
              style={{
                ...getTransitionChildren[status],
              }}
            >
              {children}
            </div>
            </div>
          )}

        </ReactTransition>
      </TransitionGroup>
    )
  }
}

export default Transition
