import { createStore, compose, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from "../reducers/index";

const initStore = () => {
  const windowGlobal = typeof window !== 'undefined' && window

  const devtools =
  process.env.NODE_ENV === 'development' && windowGlobal.devToolsExtension
    ? window.__REDUX_DEVTOOLS_EXTENSION__ &&
      window.__REDUX_DEVTOOLS_EXTENSION__()
    : f => f;

  const store = createStore(
    rootReducer,
    compose(
      applyMiddleware(thunk),
      devtools,
    )
  );

  return store;
};

export default initStore;
