export const GET_WL = "GET_WL";
export const ARTICLE_LOADING = "ARTICLE_LOADING";
export const ARTICLE_LOADED = "ARTICLE_LOADED";
export const ARTICLE_ERROR = "ARTICLE_ERROR";

export const POD_LOADING = "POD_LOADING";
export const POD_LOADED = "POD_LOADED";
export const POD_ERROR = "POD_ERROR";

export const WL_LOADED = "WL_LOADED";
export const WL_LOADING = "WL_LOADING";
export const WL_ERROR = "WL_ERROR";
export const PARENT_SITE = "PARENT_SITE";
export const SUB_SITE_SECTIONS = "SUB_SITE_SECTIONS";
export const SUB_SITE = "SUB_SITE";
export const HORIZ_NUM = "HORIZ_NUM";
export const HORIZ_DATA = "HORIZ_DATA";
export const SECTION_NUM = "SECTION_NUM";
export const SCROLL_POS = "SCROLL_POS";
export const HORIZ_SECTION_NUM = "HORIZ_SECTION_NUM";
export const SCROLL_SECTION = "SCROLL_SECTION";
export const CLICK_SECTION = "CLICK_SECTION";
export const GLOBAL_SCROLL = "GLOBAL_SCROLL";
export const SET_REFERRER = "SET_REFERRER";
