/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

// import './src/css/font-awesome.css';
// import './src/css/ae_header.css';
// import './src/css/ae_footer.css';
// import './src/css/ink-flex.css';
//
// import './src/css/new_header.css';
// import './src/css/new_styles.css';
// import './src/css/scrollpage.css';
// import './src/css/scrollsite.css';

import './src/css/new-brand.css'





import wrapWithProvider from "./wrap-with-provider"





export const onClientEntry = async () => {
  if (typeof IntersectionObserver === `undefined`) {
    await import(`intersection-observer`);
  }
}

export const wrapRootElement = wrapWithProvider
