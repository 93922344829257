// src/js/actions/index.js
import { GET_WL,ARTICLE_LOADING,ARTICLE_LOADED,ARTICLE_ERROR, POD_ERROR,POD_LOADING,POD_LOADED,WL_ERROR,WL_LOADING,WL_LOADED, SUB_SITE_SECTIONS,PARENT_SITE, SUB_SITE,HORIZ_NUM,HORIZ_DATA, SCROLL_POS,SECTION_NUM, HORIZ_SECTION_NUM,SCROLL_SECTION, CLICK_SECTION,GLOBAL_SCROLL, SET_REFERRER } from "../constants/action-types";

import axios from 'axios'

// https://www.aecom.com/without-limits/wp-json/wp/v2/article?include[]=5693&_embed
//
// include[]=496&include[]=503

const WLEndPoint = 'https://www.aecom.com/without-limits/wp-json/wp/v2/article?'
// const podUrl = 'https://api.whooshkaa.com/episodes?_sort=-published_at&podcast_id=5732&limit=10'



// axios(`${WLEndPoint}/?id=${ID}&_embed`)
export function getArticle() {
  return function(dispatch) {
    dispatch({ type: "ARTICLE_LOADING" });
    return axios.get(`${WLEndPoint}`)
        .then((response) => {
          dispatch({ type: "ARTICLE_LOADED", payload: response.data });
      }).catch((error) => {

        dispatch({ type: "ARTICLE_ERROR", payload: error });

      });
  };
}

export function getPodcasts(id, url) {
  return function(dispatch) {
    dispatch({ type: "POD_LOADING" });
    // return axios.get(`${WLEndPoint}`)
        return axios.get(`${url}&id=${id}`)
        .then((response) => {

          dispatch({ type: "POD_LOADED", payload: response.data });
      }).catch((error) => {

        dispatch({ type: "POD_ERROR", payload: error });

      });
  };
}

export function getWL(id) {
let IDQuery = ""
if(Array.isArray(id)){

for(let ID of id){
  IDQuery += `include[]=${ID}&`
}
}else{
  IDQuery = `include[]=${id}&`
}
  return function(dispatch) {
    dispatch({ type: "WL_LOADING" });
    // return axios.get(`${WLEndPoint}`)
        return axios.get(`${WLEndPoint}${IDQuery}_embed`)
        .then((response) => {
          dispatch({ type: "WL_LOADED", payload: response.data });
      }).catch((error) => {

        dispatch({ type: "WL_ERROR", payload: error });

      });
  };
}



export function setSections(sections,site,subsite) {
    return function(dispatch) {
  dispatch({ type: "SUB_SITE_SECTIONS", payload: sections });
  dispatch({ type: "PARENT_SITE", payload: site });
    dispatch({ type: "SUB_SITE", payload: subsite });

    }

  }

  export function setHorizontal(horizontalSectionNumbers,horizontalData) {
      return function(dispatch) {
            dispatch({ type: "HORIZ_NUM", payload: horizontalSectionNumbers });
            dispatch({ type: "HORIZ_DATA", payload: horizontalData });
      }

    }
    export function setSectionNumber(sectionNumber) {
        return function(dispatch) {
              dispatch({ type: "SECTION_NUM", payload: sectionNumber });

        }

      }

      export function setScroll(scrollpos) {
          return function(dispatch) {
                dispatch({ type: "SCROLL_POS", payload: scrollpos });

          }

        }

        export function setHorizontalSection(HorizontalSectionNum) {
            return function(dispatch) {
                  dispatch({ type: "HORIZ_SECTION_NUM", payload: HorizontalSectionNum });

            }

          }

          export function scrolltoSection(scrollElement) {
              return function(dispatch) {
                    dispatch({ type: "SCROLL_SECTION", payload: scrollElement });

              }

            }

            export function clicktoSection(scrollElement) {
                return function(dispatch) {
                      dispatch({ type: "CLICK_SECTION", payload: scrollElement });

                }

              }
              export function SetGlobalScroll(scroll) {
                  return function(dispatch) {
                        dispatch({ type: "GLOBAL_SCROLL", payload: scroll });

                  }

                }


                export function setReferrer(url) {
                    return function(dispatch) {
                          dispatch({ type: "SET_REFERRER", payload: url });

                    }

                  }
