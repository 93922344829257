import React, {Component} from 'react';
import withLocation from "./withLocation"
const { NODE_ENV } = process.env



class GAPageScroll extends Component {


  constructor(props){
       super(props);
       this.onScroll = this.onScroll.bind(this); //bind function once
   }

   componentDidMount() {
     if (typeof window !== 'undefined') {
       window.addEventListener('scroll', this.onScroll, false);
     }
   }

   componentWillUnmount() {
     if (typeof window !== 'undefined') {
       // you need to unbind the same listener that was binded.
       window.removeEventListener('scroll', this.onScroll, false);
     }
   }


onScroll(){

    let Location = this.props.location.pathname

        var h =  document.documentElement,
            b =  document.body,
            st =  'scrollTop',
            sh =  'scrollHeight';

        var percent = parseInt ( (h[st]||b[st]) / ((h[sh]||b[sh]) - h.clientHeight) * 100);


    if (percent === 25)
    {

      if(NODE_ENV === 'production'){
        typeof window !== "undefined" && window.gtag("event", "Scrolling" ,{"event_category":"moreThan25" ,"event_label":Location})
      }
    }
    else if (percent === 50)
    {

      if(NODE_ENV === 'production'){
        typeof window !== "undefined" && window.gtag("event", "Scrolling" ,{"event_category":"moreThan50","event_label":Location})
      }
    }
    else if (percent === 75)
    {

        if(NODE_ENV === 'production'){
            typeof window !== "undefined" && window.gtag("event", "Scrolling" ,{"event_category":"moreThan75" ,"event_label":Location})
        }
    }
    else if (percent === 90)
    {



      if(NODE_ENV === 'production'){
        typeof window !== "undefined" && window.gtag("event", "Scrolling" ,{"event_category":"moreThan90","event_label":Location})
      }
    }
    }




  render() {

    return (<></>

          )

  }

}
export default withLocation(GAPageScroll)
