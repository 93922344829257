import { GET_WL, ARTICLE_LOADING, ARTICLE_LOADED, ARTICLE_ERROR, POD_LOADING, POD_LOADED, POD_ERROR,WL_ERROR,WL_LOADING,WL_LOADED,SUB_SITE_SECTIONS,PARENT_SITE,SUB_SITE,HORIZ_NUM,HORIZ_DATA,SCROLL_POS,SECTION_NUM,HORIZ_SECTION_NUM,SCROLL_SECTION, CLICK_SECTION,GLOBAL_SCROLL,SET_REFERRER } from "../constants/action-types";



const initialState = {
  articles: [],
  pods: [],
  WL:[],
  error:"",
  sections:[],
  parent:[],
  subsite:[],
  HorizontalNumbers:[],
  HorizontalData:[],
  sectionNumber:0,
  scrollPos:0,
  ScrollSection:"",
  ClickSection:"",
  globalScroll:{percent:0, hide:false},
  Referrer:{url:"https://aecom.com",target:"_blank"},
  HorizontalSectionNum:{number:0,active:false},
  micrositeID:"5d430926ac7bc762ecb81769"
};

function rootReducer(state = initialState, action) {


    if (action.type === ARTICLE_ERROR) {
       console.log(action.payload)
      return Object.assign({}, state, {
        error: "there was a problem loading the data - " + action.payload
      });
}
     if (action.type === POD_ERROR) {
        console.log(action.payload)
       return Object.assign({}, state, {
         error: "there was a problem loading the data - " + action.payload
       });

      }

    if (action.type === ARTICLE_LOADED) {
  return Object.assign({}, state, {
    articles: state.articles.concat(action.payload),
    error: ""
  });
}

  if (action.type === ARTICLE_LOADING) {
return Object.assign({}, state, {
  error: "LOADING"
});
}
if (action.type === POD_LOADING) {
return Object.assign({}, state, {
error: "LOADING"
});
}
if (action.type === POD_LOADED) {
return Object.assign({}, state, {
pods: state.pods.concat(action.payload),
error: ""
});
}

if (action.type === WL_LOADING) {
return Object.assign({}, state, {
error: "LOADING"
});
}
if (action.type === WL_LOADED) {
return Object.assign({}, state, {
WL: state.WL.concat(action.payload),
error: ""
});
}

if (action.type === PARENT_SITE) {
return Object.assign({}, state, {
parent: action.payload,
error: ""
});
}


if (action.type === SUB_SITE_SECTIONS) {
return Object.assign({}, state, {
sections: action.payload,
error: ""
});
}
if (action.type === SUB_SITE) {
return Object.assign({}, state, {
subsite: action.payload,
error: ""
});
}

if (action.type === HORIZ_NUM) {
return Object.assign({}, state, {
HorizontalNumbers: action.payload
});
}

if (action.type === HORIZ_DATA) {
return Object.assign({}, state, {
HorizontalData: action.payload
});
}

if (action.type === SECTION_NUM) {
return Object.assign({}, state, {
sectionNumber: action.payload
});
}

if (action.type === SCROLL_POS) {
return Object.assign({}, state, {
scrollPos: action.payload
});
}

if (action.type === HORIZ_SECTION_NUM) {
return Object.assign({}, state, {
HorizontalSectionNum: action.payload
});
}

if (action.type === SCROLL_SECTION) {
return Object.assign({}, state, {
ScrollSection: action.payload
});
}

if (action.type === CLICK_SECTION) {
return Object.assign({}, state, {
ClickSection: action.payload
});
}

if (action.type === GLOBAL_SCROLL) {
return Object.assign({}, state, {
globalScroll: action.payload
});
}












  return state;
};








export default rootReducer;
